<template>
  <div class="enumeration-dashboard">
    <vsud-loader v-if="isLoading"></vsud-loader>
    <div class="enumuration-data" v-else>
      <div class="row mb-4" v-if="userClass=='superadmin'||userClass=='executive'">
        <div class="col-md-3">
          <div class="input-group text-xxs">
              <div class="input-group-prepend">
                  <div 
                      class="input-group-text" 
                      style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                      &nbsp; <i class="fa fa-calendar"></i>
                  </div>
              </div>
              <select @change="changePhase()" v-model="byPhase" name="report-type" class="form-control" id="inlineFormInputGroup">
                <option value=""> &nbsp; Phase all time</option> 
                <option value="2021"> &nbsp; 2021 ( Phase 1 )</option>                                           
                <option value="2022"> &nbsp; 2022 ( Phase 2 )</option>
              </select>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Businesses</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class="applicants-holder" style="height:120px">
                <table class="table table-bordered">
                  <tr>
                    <td class="text-info" width="140"><i class="fas fa-users"></i>Total </td><td :style="styleSmallTable()" >
                      {{dashboard.enumeration.toLocaleString("en-US")}}
                      <span v-if="isLoading==false"  
                          class="badge badge-sm me-1 bg-gradient-info" 
                          style="font-size: 0.60em; float:right">
                          100.0 %
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-success"><i class="fas fa-file"></i>New </td><td :style="styleSmallTable()" > 
                      {{dashboard.created.toLocaleString("en-US")}}
                      <span v-if="isLoading==false"  
                          class="badge badge-sm me-1 bg-gradient-success" 
                          style="font-size: 0.60em; float:right">
                          100.0 %
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-warning"><i class="fas fa-book"></i>Updated &nbsp;</td><td :style="styleSmallTable()" > 
                      {{dashboard.updated.toLocaleString("en-US")}} 
                      <span v-if="isLoading==false"  
                          class="badge badge-sm me-1 bg-gradient-warning" 
                          style="font-size: 0.60em; float:right">
                          100.0 %
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Enumeration Stats</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body">
              <div class="enumeration-stats-holder" style="height:120px">
              <table class="table table-bordered">
                <tr>
                  <td class="text-info" width="130"><i class="fas fa-calendar"></i> {{ getFullMonth() }}</td><td :style="styleSmallTable()" > {{dashboard.stats.month.toLocaleString("en-US")}}</td>
                </tr>
                <tr>
                  <td class="text-success"><i class="fas fa-calendar"></i> This Week</td><td :style="styleSmallTable()" > {{dashboard.stats.week.toLocaleString("en-US")}}</td>
                </tr>
                <tr>
                  <td class="text-warning"><i class="fas fa-calendar"></i> Today </td><td :style="styleSmallTable()" > {{dashboard.stats.today.toLocaleString("en-US")}}</td>
                </tr>
              </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Area Enumeration Activity</h6>
                  <p class="text-sm">
                    Top 3 Areas
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li>
                        <router-link
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Area Enumeration Activity"
                          target="_blank"
                          :to="{name:'Area Enumeration Activity'}">
                          &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="state-enum-top-3-holder" style="height:120px">
              <vsud-spinner v-if="isLoading==true"></vsud-spinner>
              <table class="table table-bordered" v-else>
                  <tr v-for="(area, index) in dashboard.areas.top3" :key="index">
                    <td  width="130" :style="'color : '+styleColorSmTable(index)">
                      <i class="fas fa-map-marker-alt"></i> {{capitalizeFirstLetter(area.item.toLowerCase())}}
                    </td>
                    <td :style="styleSmallTable()"> 
                      {{area.businesses_added.toLocaleString("en-US")}}
                    </td>
                    <td :style="'border-left:1px solid #EEE; padding-left:9px'">
                      {{((area.businesses_added / dashboard.created) * 100).toFixed(2)}} %
                    </td>
                  </tr>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-md-8" v-if="userClass=='superadmin'||userClass=='executive'">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-7 col-7">
                  <h6>Enumeration activity by area</h6>
                  <p class="text-sm mb-0">
                    &nbsp;
                  </p>
                </div>
                <div class="col-lg-5 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li class="active">. . .</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" >
              <div class="enum-activity-hoder" style="height:420px">
                <br>
                <vsud-spinner v-if="isLoading"></vsud-spinner>
                <bar-column :chartData="EnumerationActivityArea" v-else></bar-column>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8" v-else>
            <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Enumeration Activity</h6>
                  <p class="text-sm mb-0">
                    ...
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable"
                    >
                      <li>
                        <router-link
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="State Enumuration Activity"
                          target="_blank"
                          :to="{name:'Enumeration Activity'}">
                          &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="enum-activity-hoder" style="height:450px">
                <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                <enumeration-activity :chartData="chartEnumerationActivity" :plugins="{ legend : {display:false} }" v-else></enumeration-activity>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Recent Enumeration</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div></div>
            <div class="card-body" :style="(!isLoading)? 'height:450px; overflow:auto' : ''">
                <div class="recently-update-holder">
                  <vsud-spinner v-if="isLoading==true"></vsud-spinner>
                  <updated-beneficiaries :listData="dashboard.recent" v-else></updated-beneficiaries>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-4">
          <div class="col-md-12">
              <div class="card" >
                <div class="pb-0 card-header">
                  <h6>Enumeration activity by nature of business</h6>
                  <div class="botton-holder small mt-3">
                    Top 20
                  </div>
                </div>
                <div>
                </div>
                <div class="card-body" style="height:450px">
                  <vsud-spinner v-if="isNatureOfBusinessLoading"></vsud-spinner>
                  <bar-stacked :chartData="EnumerationActivityByNatureOfBusiness" v-else></bar-stacked>
                </div>
              </div>
          </div>
      </div>
      <div class="row my-4">
        <div :class="(chartSchemeActivity.length==3)? 'col-md-4 mb-4' : 'col-md-6 mb-4' " v-for="(scheme,index) in chartSchemeActivity" :key="index">
            <div class="card">
              <div class="card-header pb-0">
                <div class="row">
                  <div class="col-lg-6 col-7">
                    <h6>{{scheme.name}}</h6>
                    <p class="text-sm mb-0">
                      ...
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body">
                  <pie :chartData="scheme.chartSchemeActivity"></pie>
              </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12" v-if="userClass=='superadmin'||userClass=='project_owner'">
          <div class="row"> 
            <div class="col-md-4">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-6 col-9">
                      <h6>Top Businesses</h6>
                      <p class="text-sm mb-0">
                        By total count
                      </p>
                    </div>
                    <div class="col-lg-6 col-3 my-auto text-end">
                      <div class="dropdown float-lg-end pe-4">
                        <a
                          class="cursor-pointer"
                          id="dropdownTable"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                        </a>
                        <ul
                          class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                          aria-labelledby="dropdownTable">
                          <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                          <li>&nbsp; . . .</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" style="height:450px; overflow:auto" >
                  <vsud-spinner v-if="isTopBusinessesLoading==true"></vsud-spinner>
                  <template v-else>
                    <div class="top-businesses-holder mb-3"  v-for="(top, index) in EnumerationTopBusinesses" :key="index">
                      <div v-if="index<40">
                        <div class="top" style="font-size:12px">
                        <b># {{ index + 1}}. {{top.item}} :</b> <br>[ {{ (( top.item_count / dashboard.enumeration ) * 100).toFixed(2) }} % ]
                        </div>
                        <div class="middle">
                            <vsud-progress
                              color="info"
                              class="mx-auto"
                              variant="gradient"
                              barType="small"
                              :percentage="(( top.item_count / dashboard.enumeration ) * 100).toFixed(2)"
                            />
                        </div>
                        <div class="bottom-data" style="text-align:right; font-size:10px">
                          <b> 
                            <span class="text-info">{{top.item_count.toLocaleString("en-us")}}</span> 
                            <span> of {{dashboard.enumeration.toLocaleString("en-us")}}</span>
                          </b>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
                <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-6 col-7">
                      <h6>Enumeration Activity</h6>
                      <p class="text-sm mb-0">
                        ...
                      </p>
                    </div>
                    <div class="col-lg-6 col-5 my-auto text-end">
                      <div class="dropdown float-lg-end pe-4">
                        <a
                          class="cursor-pointer"
                          id="dropdownTable"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                        </a>
                        <ul
                          class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                          aria-labelledby="dropdownTable"
                        >
                          <li>
                            <router-link
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="State Enumuration Activity"
                              target="_blank"
                              :to="{name:'Enumeration Activity'}">
                              &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" style="height:450px">
                  <vsud-nodata></vsud-nodata>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="template-holder">
        <div class="row mb-4">
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="pb-0 card-header">
                <div class="row">
                  <div class="col-lg-9 col-7">
                    <h6>Disability</h6>
                    <p class="text-sm">
                      . . .
                    </p>
                  </div>
                  <div class="col-lg-3 col-5 my-auto text-end">
                    <div class="dropdown float-lg-end pe-4">
                      <a
                        class="cursor-pointer"
                        id="dropdownTable"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                      </a>
                      <ul
                        class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                        aria-labelledby="dropdownTable">
                        <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                        <li style="cursor:pointer">. . .</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="text-center p-1 mb-3" style="height:320px">
                  <vsud-nodata></vsud-nodata>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="pb-0 card-header">
                <div class="row">
                  <div class="col-lg-9 col-7">
                    <h6>Gender</h6>
                    <p class="text-sm">
                      By total count
                    </p>
                  </div>
                  <div class="col-lg-3 col-5 my-auto text-end">
                    <div class="dropdown float-lg-end pe-4">
                      <a
                        class="cursor-pointer"
                        id="dropdownTable"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                      </a>
                      <ul
                        class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                        aria-labelledby="dropdownTable">
                        <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                        <li style="cursor:pointer">. . .</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class=" text-center p-1 mb-3" style="height:320px">
                  <vsud-spinner v-if="isActivityLoading.gender==true" custStyle="height:260px"></vsud-spinner>
                  <pie :chartData="EnumerationActivityBy.gender" v-else></pie>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="pb-0 card-header">
                <div class="row">
                  <div class="col-lg-9 col-7">
                    <h6>Age group</h6>
                    <p class="text-sm">
                      By total count
                    </p>
                  </div>
                  <div class="col-lg-3 col-5 my-auto text-end">
                    <div class="dropdown float-lg-end pe-4">
                      <a
                        class="cursor-pointer"
                        id="dropdownTable"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                      </a>
                      <ul
                        class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                        aria-labelledby="dropdownTable">
                        <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                        <li style="cursor:pointer">. . .</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class=" text-center p-1 mb-3" style="height:320px">
                  <vsud-spinner v-if="isActivityLoading.age==true" custStyle="height:260px"></vsud-spinner>
                  <mono-chrome :chartData="EnumerationActivityBy.age" v-else></mono-chrome>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import EnumerationActivity from "@/views/components/dashboard/EnumerationActivity.vue";
import BeneficiaryAcitivity from "@/views/components/dashboard/BeneficiaryAcitivity.vue";
import SchemeActivity from "@/views/components/dashboard/SchemeActivity.vue";
import UpdatedBeneficiaries from "@/views/components/dashboard/UpdatedBeneficiaries/Layout.vue";
import AuthService from '@/services/auth.service.js';
import VsudSpinner from "@/components/VsudSpinner.vue";
import Pie from '@/views/components/dashboard/charts/appexchart/PieDonut.vue';
import DisabilityGenderAge from '@/views/dashboard/components/DisabilityGenderAge/Data.vue';
import DisabilityGenderAgeNoData from '@/views/dashboard/components/DisabilityGenderAge/NoData.vue';
import VsudProgress from "@/components/VsudProgress.vue";
import VsudLoader from "@/components/VsudLoader.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import BarColumn from '@/views/components/dashboard/charts/appexchart/BarColumn.vue';
import BarStacked from '@/views/components/dashboard/charts/appexchart/BarStacked.vue';
import SemiCircleGuage from '@/views/components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';
import MonoChrome from '@/views/components/dashboard/charts/appexchart/PolarAreaChart.vue';
import SemiCircleGuageChart from '../components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';

export default {
  name: "enumeration",
  data() {
    var defmsg = '--';

    return {
      /**
       * Actively Used 
       */
      dashboard : {
        enumeration : defmsg,
        updated : defmsg,
        created : defmsg,
        stats :{
          month : defmsg,
          today : defmsg,
          week : defmsg,
        },
        recent : null,
        areas : {
          top3 : [],
          all  : []
        }
      },
      byPhase : '',
      EnumerationActivityArea : {},
      EnumerationActivityByNatureOfBusiness : {},
      EnumerationTopBusinesses : {},
      EnumerationActivityBy : {
        age : {},
        gender : {},
        disability : {}
      },
      isLoading : false,
      isAreaLoading : false,
      isNatureOfBusinessLoading : false,
      isTopBusinessesLoading : false,
      isActivityLoading : {
        age : false,
        gender : false,
        disability : false
      },


      /**
       * To be reviwed or removed : 
       */
      iconBackground: "bg-gradient-success",
      Beneficiaries : '--',
      UpdatedBeneficiaries : '--',
      NotUpdatedBeneficiaries : '--',
      EnumerationActivityWeek : '--',
      EnumerationActivityMonth : '--',
      EnumerationActivityToday : '--',
      LocalGovernments  : '--',
      titleZoneAcitvity : '...',
      Users : '--',
      LocalGov : '--',
      Wards : '--',
      userClass : '',
      States : '--',
      Schemes : [],
      chartStateActivity : {},
      chartEnumerationActivity : {},
      chartBeneficiaryActivity : {},
      chartSchemeActivity : [],
      chartZoneActivity : [],
      listRecentlyUpdated : [],
      SchemeTopBusinesses : [],
      EnumerationActivityState : [],
      chartToggleData : {
        consultant : 'enumerated',
        state : 'enumerated',
        zone : 'enumerated'
      },
      chartToggleZoneScheme : 'no',
      titleSchemeTopBusinesses : 'TraderMoni',
      isZoneLoading : false,
      isBeneficiaryLoading : false,
    };
  },

  created()
  {
    this.RunDashboard();
    this.timer = setInterval(this.RunDashboard, 300000);
  },

  components: {
    MiniStatisticsCard,
    EnumerationActivity,
    BeneficiaryAcitivity,
    SchemeActivity,
    UpdatedBeneficiaries,
    VsudSpinner,
    Pie,
    DisabilityGenderAge,
    VsudProgress,
    VsudLoader,
    VsudNodata,
    DisabilityGenderAgeNoData,
    BarColumn,
    BarStacked,
    SemiCircleGuage,
    SemiCircleGuageChart,
    MonoChrome
  },

  methods : {

    /**
     * Active methods
     * @desc : include old & new ones 
     */
    RunDashboard()
    {
      this.getUserClass();
      this.getDashboardAnalytics();
      this.getAreaAnalytics();
      this.getAnalyticsNatureOfBusiness();
      this.getActivityByAge();
      this.getActivityByGender();
    },

    async getDashboardAnalytics()
    {
       this.isLoading = true;

       try{

          const year = (this.byPhase!=='')? '&year'+this.byPhase : '';
          const response = await AuthService.getDashboard();

          if(response.data!==undefined)
          {
              let temp = response.data.updateSummary;
              let csum = response.data.createSummary;

              this.dashboard.enumeration = parseInt(temp.businesses) + parseInt(csum.businesses_added);
              this.dashboard.updated = temp.businesses_updated;
              this.dashboard.created = csum.businesses_added;
              this.dashboard.stats.today = csum.today;
              this.dashboard.stats.week = csum.this_week;
              this.dashboard.stats.month = csum.this_month;

              // Recent enumeration
              let temp_recent = [];
              let created = response.data.recentlyCreated;
              let updated = response.data.recentlyUpdated;

              // -: Created
              for(var i = 0; i<created.length; i++)
              {
                 let temp_c = created[i];
                 temp_c['type'] = 'new';
                 temp_recent.push(temp_c);
              }

              // -: Updated
              for(var j = 0; j<updated.length; j++)
              {
                let temp_u  = updated[j];
                temp_u['type'] = 'updated';
                temp_recent.push(temp_u);
              }

              const shuffle = (arr) => { 
                  return arr.sort(() => Math.random() - 0.5); 
              };

              this.dashboard.recent = shuffle(temp_recent);
              this.getTopBusinesses();
          }
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
       finally
       {
         this.isLoading = false
       }
    },

    async getAreaAnalytics()
    {
      
       this.isAreaLoading = true;

       try{

          const response = await AuthService.getDashboard('/areas');

          if(response.data!==undefined)
          {
              let area = response.data.enumerationActivityByAreas;

              // Sort
              area.sort(function(a, b) { return b.businesses_added - a.businesses_added; });

              console.log(area);

              if(area.length>0)
              {
                this.dashboard.areas.top3 = area.slice(0, 3);
                this.dashboard.areas.all  = area;

                this.EnumerationActivityArea = {
                  categories : [],
                  datasets : [
                      {
                         name : 'Area',
                         data : [],
                      },
                  ]
                }

                for(var i = 0; i<area.length; i++)
                {
                  this.EnumerationActivityArea.categories[i] = area[i].item;
                  this.EnumerationActivityArea.datasets[0].data[i] = area[i].businesses_added
                }

              }
          }

       }
       catch(error)
       {
         console.log(error)
       }
       finally{
        this.isAreaLoading = false
       }
    },

    async getAnalyticsNatureOfBusiness()
    {
        this.isNatureOfBusinessLoading = true;

        try{

          const response = await AuthService.getDashboard('/enumeration/activity');

          if(response.data!==undefined)
          {
              let nature = response.data.enumerationActivityByNatureOfBusiness;

              if(nature.length>0)
              {
                this.EnumerationActivityByNatureOfBusiness = {
                  categories : [],
                  datasets : [
                      {
                         name : 'Added',
                         data : [],
                      },
                      {
                        name :  'Updated',
                        data : []
                      }
                  ]
                }

                let size = (nature.length>=20)? 20 : nature.length;

                for(var i = 0; i<size; i++)
                {
                  this.EnumerationActivityByNatureOfBusiness.categories[i] = nature[i].item;
                  this.EnumerationActivityByNatureOfBusiness.datasets[0].data[i] = nature[i].businesses_added;
                  this.EnumerationActivityByNatureOfBusiness.datasets[1].data[i] = nature[i].businesses_updated;
                }

              }
          }

       }
       catch(error)
       {
         console.log(error)
       }
       finally{
        this.isNatureOfBusinessLoading = false
       }
    },

    async getTopBusinesses()
    {
        this.isTopBusinessesLoading = true;

        try{

          const response = await AuthService.getDashboard('/enumeration/top-businesses/40');

          if(response.data!==undefined)
          {
              let top = response.data.topBusinesses;

              if(top.length>0)
              {
                  this.EnumerationTopBusinesses = top;
              }
          }

       }
       catch(error)
       {
         console.log(error)
       }
       finally{
        this.isTopBusinessesLoading = false;
       }
    },

    async getActivityByAge()
    {
        this.isActivityLoading.age = true;

        try{

          const response = await AuthService.getDashboard('/enumeration/age');

          if(response.data!==undefined)
          {
              let dataAge = response.data.enumerationActivityByAgeRange;

              this.EnumerationActivityBy.age = {
                labels : [],
                datasets : []
              };

              for(var j = 0; j<dataAge.length; j++)
              {
                this.EnumerationActivityBy.age.labels[j] = dataAge[j].item;
                this.EnumerationActivityBy.age.datasets[j] = dataAge[j].item_count;
              }
          }

       }
       catch(error)
       {
         console.log(error)
       }
       finally{
        this.isActivityLoading.age = false;
       }
    },

    async getActivityByGender()
    {
        this.isActivityLoading.gender = true;

        try{

          const response = await AuthService.getDashboard('/enumeration/gender');

          if(response.data!==undefined)
          {
              let dataGender = response.data.enumerationActivityByGender;

              this.EnumerationActivityBy.gender = {
                labels : [],
                datasets : []
              };

              for(var k = 0; k<dataGender.length; k++)
              {
                if(dataGender[k].gender!==null)
                {
                  this.EnumerationActivityBy.gender.labels[k] = dataGender[k].gender;
                  this.EnumerationActivityBy.gender.datasets[k] = dataGender[k].item_count;
                }
              }
          }

       }
       catch(error)
       {
         console.log(error)
       }
       finally{
        this.isActivityLoading.gender = false;
       }
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
      }
    },

    changePhase()
    {
       this.Beneficiaries = '--';
       this.UpdatedBeneficiaries = '--';
       this.NotUpdatedBeneficiaries = '--';
       this.EnumerationActivityWeek = '--';
       this.EnumerationActivityMonth = '--';
       this.EnumerationActivityToday = '--';

       this.RunDashboard();
    },

    /**
     * To be reviwed 
     * @desc : some can be reused majority have to be deleted 
     */
    async GetUsers()
    {
       try
       {
          const response = await AuthService.getUsers();
          this.Users = (response.meta.pagination.total>0)? response.meta.pagination.total : 0;
       }
       catch(error)
       {
          if(error)
          { 
            console.log(error);
            AuthService.CheckResponseError(error);
          }
       }

    },

    async GetStates()
    {
        try{
          const response = await AuthService.getStates('?perPage=40');

          if(response.meta.pagination.total>0&&response.data.length>0)
          {
            var States = response.data.length;
            var LocalGovernments = 0;
            var Wards = 0;

            for(var i=0; i<response.data.length; i++)
            {
                LocalGovernments += (response.data[i].localGovernments.length>0)? response.data[i].localGovernments.length : 0;
                
                for(var j=0; j<response.data[i].localGovernments.length>0; j++)
                {
                  Wards += (response.data[i].localGovernments[j].wards.length>0)? response.data[i].localGovernments[j].wards.length : 0;
                }
            }

            this.States = States
            this.LocalGov = LocalGovernments;
            this.Wards = Wards;
          }
          else
          {
            this.States = 0;
            this.LocalGovernments = 0;
            this.Wards  = 0;
          }
      }
      catch(error)
      {
        if(error)
        { 
          console.log(error);
          AuthService.CheckResponseError(error);
        }
      }
    },

    async GetBeneficiaries()
    {
       try{
          const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
          const response = await AuthService.getBenficiaries(year);
          this.Beneficiaries = (response.meta.pagination.total>0)? response.meta.pagination.total : 0;
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
    },

    async GetSchemes()
    {
       try
       {
          const response = await AuthService.getSchemes();
          console.log(response);
          this.Schemes = response.data;
       }
       catch(error)
       {
          console.log(error);
       }
    },

    styleSmallTable()
    {
      return 'border-left:1px solid #EEE; padding-left:9px';
    },

    styleColorSmTable(index)
    {
       var color = ['#222F3E','#41AB7B','#ca800f','#425265'];

       return (index!==null)? color[index] : color[0];
    },

    async getZoneActivity(type='all')
    {
        this.isZoneLoading = true;

        try
        {
            const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
            const response = (this.chartToggleZoneScheme=='no')? await AuthService.getZoneActivity(year) : await AuthService.getZoneActivityScheme();
            var preDataSets = []; var preLabels = [];

            // # Debug
            // - Check Response data
            // console.log(response);

            if(this.chartToggleZoneScheme=='no')
            {
              var dataNo = response.data.enumerationActivityByZones;

              for(var i = 0; i<dataNo.length; i++)
              {
                  // # Debug
                  // - Style 1.1 : *
                  // preColor[i] = this.GetdynamicColors();

                  preLabels[i] = dataNo[i].name;

                  switch(type)
                  {
                    case 'all'   : preDataSets[i] = (this.chartToggleData.zone=='enumerated')? dataNo[i].updated : dataNo[i].not_updated ;break;
                    case 'month' : preDataSets[i] = dataNo[i].month ;break;
                    case 'week'  : preDataSets[i] = dataNo[i].week  ;break;
                    case 'today' : preDataSets[i] = dataNo[i].today ;break;
                  }

              }

              switch(type)
              {
                case 'all'   : this.titleZoneAcitvity = 'All time' ;break;
                case 'month' : this.titleZoneAcitvity = 'This Month' ;break;
                case 'week'  : this.titleZoneAcitvity = 'This Week' ;break;
                case 'today' : this.titleZoneAcitvity = 'Today' ;break;
              }

            }

            if(this.chartToggleZoneScheme=='FarmerMoni'||this.chartToggleZoneScheme=='MarketMoni'||this.chartToggleZoneScheme=='TraderMoni')
            {
               var dataScheme = response.data.enumerationActivityByZonesBySchemes;

               if(dataScheme!==null)
               {
                  var refinedDataScheme = this.getRefinedZoneActivity(this.chartToggleZoneScheme,dataScheme);

                  // # Debug
                  // console.log('# Debug Refined Data Scheme :');
                  // console.log(refinedDataScheme);

                  for(var i = 0; i<refinedDataScheme.length; i++)
                  {
                      preLabels[i] = refinedDataScheme[i].zone;
                      preDataSets[i] = (this.chartToggleData.zone=='enumerated')? refinedDataScheme[i].updated_count : refinedDataScheme[i].not_updated
                  }
                  
               }
            }

            this.chartZoneActivity = {

                labels : preLabels,
                datasets: [ { 
                    label : 'Zones',
                    backgroundColor : this.GetdynamicColors(),
                    data : preDataSets} ]
            }

            this.isZoneLoading = false;

        }
        catch(error)
        {
          console.log(error);
          AuthService.CheckResponseError(error);
        }
    },

    async GetSchemeTopBussinesses(type='TraderMoni')
    {
      this.isTopBusinessesLoading = true;

      try
      {
        const year = (this.byPhase!=='')? '?year='+this.byPhase : ''; 
        const response = await AuthService.getTopBusinesses(year);

        if(response.data.topBusinessesByScheme!==null)
        { 
            this.titleSchemeTopBusinesses = type;
            var SchemeTopBusinessesData = response.data.topBusinessesByScheme[type];
            
            if(SchemeTopBusinessesData==undefined||SchemeTopBusinessesData==null)
            {
              SchemeTopBusinessesData = []
            }

            this.SchemeTopBusinesses = SchemeTopBusinessesData;
        }

        this.isTopBusinessesLoading = false;
      }
      catch(error)
      {
        this.isTopBusinessesLoading = false; 
        console.log(error);
        AuthService.CheckResponseError(error);
      }
    },

    ChangeSchemeTopBusiness(type)
    {
       this.GetSchemeTopBussinesses(type)
    },

    ColorSchemeTopBusiness(type)
    {
      var color = '';

      switch(type)
      {
        case 'TraderMoni' : color = 'success' ;break;
        case 'MarketMoni' : color = 'warning' ;break;
        case 'FarmerMoni' : color = 'info' ;break;
      }

      return color ;
    },

    ChangeZoneActivity(type)
    {
        this.getZoneActivity(type);
    },

    chartToggle(type,data)
    {
        if(type=='state')
        {
            if(data=='enumerated')
            {
              this.chartToggleData.state = 'enumerated';
            }
            else
            {
              this.chartToggleData.state = 'not-enumerated';
            }

            this.getDashboardAnalytics();
        }

        if(type=='zone')
        {
            if(data=='enumerated')
            {
              this.chartToggleData.zone = 'enumerated';
            }
            else
            {
              this.chartToggleData.zone = 'not-enumerated';
            }

            this.getZoneActivity();
        }
    },

    isToggleButtonActive(type,data)
    {
        let resp = '';
        var btna = 'bg-gradient-success';
        var btno = 'bg-gradient-secondary';

        if(type=='state')
        {
           if(data==this.chartToggleData.state)
           {
              resp = btna;
           }
           else
           { 
              resp = btno;
           } 
        }

        if(type=='zone')
        {
           if(data==this.chartToggleData.zone)
            resp = btna;
           else
            resp = btno; 
        }

        if(type=='consultant')
        {
           if(data==this.chartToggleData.consultant)
            resp = btna;
           else
            resp = btno; 
        }

        return resp;
    },  

    GetdynamicColors() 
    {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
    },

    getFullMonth()
    {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const date  = new Date();

      return month[date.getMonth()];
    },
    
    capitalizeFirstLetter(string) 
    {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    ChangeZoneActivityByScheme()
    {
       this.getZoneActivity();
    },

    getSchemeIdParam(data)
    {
      var scheme = "";

      if(data!=="")
      {       
        for(var i = 0; i<this.Schemes.length; i++)
        {
          if(this.Schemes[i].name==data)
          {
             scheme = this.Schemes.id;
          }
        }
      }

      return scheme;
    },

    getRefinedZoneActivity(scheme, data)
    {
        var zones = ['North Central','North East','North West','South East','South South','South West'];
        var refined = [];
        var count = 0;

        for(var i=0; i<zones.length; i++)
        {
           var temp = data[zones[i]];

           // # Debug
           // console.log('# Debug Get Refined Zone Activity :');
           // console.log(temp);

           for(var j=0; j<temp.length; j++)
           {
              if(scheme==temp[j].scheme)
              {
                refined[count] = temp[j];

                count++;
              }
           }
        }

        return refined;
    }
  }
};
</script>
<style scoped>
  .fs-form-control-select
  {
      border : 1px solid #EEE;
      padding: 0.19em 0.9em;
      font-size: 0.85em;
      border-radius: 0.45rem;
  }
</style>