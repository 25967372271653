<template>
    <div class="lint-one-element">
        <loader v-if="isSubmitLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <vsud-alert
            v-if="isSubmitted"
            icon="fa fa-bell"
            :dismissible="true"
            :color="alertColor">
            {{ alertMessage }}
        </vsud-alert>
        <div class="py-4 container-fluid">
            <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                            <h6>Beneficiary Details</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <router-link :to="{ name: 'Beneficiaries' }">
                                Close <i class="fas fa-times-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Cancel" aria-label="Cancel"></i><span class="sr-only">Close</span>
                            </router-link>
                        </div>
                        </div>
                    </div>
                    <div class="card-body p-4">
                        <div class="users-data row justify-content-md-center" v-if="isLoading==false && message==''">
                            <div class="col-md-3 text-center">
                                <div class="mt-1" v-if="viewData.photoLink!==null">
                                    <img :src="cleanImg(viewData.photoLink)" class="rounded border" style="height:250px;width:250px" />
                                </div>
                                <div class="mt-1" v-else>
                                    <img :src="imgDef" class="img-fluid rounded" />
                                    <p class="pt-2">
                                        [ No-Photo Yet ]
                                    </p>
                                </div>
                                <div class="status-vm">
                                    <p class="pt-1">
                                        <vsud-badge 
                                            :color="renderStatus(viewData.status, viewData.updated, 'color')"
                                            variant="gradient" size="sm">
                                            {{ renderStatus(viewData.status, viewData.updated) }}
                                        </vsud-badge>
                                    </p>
                                </div>
                            </div>
                            <!-- Personal Information -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5">
                                        <i class="fas fa-user"></i> Personal Information
                                    </h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr> 
                                                <td style="width:40%"><b>Owner</b></td>
                                                <td>{{viewData.ownerName}}</td>
                                            </tr>
                                            <tr> <td><b>Phone No.</b></td><td>{{viewData.ownerPhone}}</td> </tr>
                                            <tr> <td><b>Gender</b></td><td>{{viewData.gender}}</td></tr>
                                            <tr>
                                                <td><b>Age</b></td>
                                                <td v-if="viewData?.ageRange!==undefined">
                                                    <span v-if="viewData.ageRange!==null">
                                                        {{ viewData.ageRange.range }}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else> . . . </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Business Information-->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-home"></i> Business Information</h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr>
                                                <td style="width:40%"><b>Name of Business</b></td>
                                                <td>{{viewData.name}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Trade/Sector</b></td>
                                                <td v-if="viewData?.natureOfBusiness!==undefined">
                                                    <span v-if="viewData.natureOfBusiness!==null">
                                                        {{viewData.natureOfBusiness.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Business Registration</b></td>
                                                <td v-if="viewData?.businessRegType!==undefined">
                                                    <span v-if="viewData.businessRegType!==null">
                                                        {{viewData.businessRegType.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Address</b></td>
                                                <td>{{viewData.address}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Started Business</b></td>
                                                <td v-if="viewData.started!==null">
                                                    <span >
                                                        {{viewData.started}}
                                                    </span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>No. of Employees</b></td>
                                                <td v-if="viewData?.noOfEmployees!==undefined">
                                                    <span v-if="viewData.noOfEmployees!==null">
                                                        {{viewData.noOfEmployees.range}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Average Annual Income</b></td>
                                                <td v-if="viewData?.annualIncome!==undefined">
                                                    <span v-if="viewData.annualIncome!==null">
                                                        {{viewData.annualIncome.range}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Regsitered with NGSIRS</b></td>
                                                <td v-if="viewData?.registeredIrs!==undefined">
                                                    <span v-if="viewData.registeredIrs!==null">
                                                        {{(viewData.registeredIrs==true)? 'Yes' : 'No'}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Pay Taxes</b></td>
                                                <td>{{(viewData.payTaxes==true)? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Registered with Assoc./Union</b></td>
                                                <td v-if="viewData?.association!==undefined">
                                                    <span v-if="viewData.association!==null">
                                                        {{viewData.association.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Social Information -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5">
                                        <i class="fas fa-users"></i> Socail Information
                                    </h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr> 
                                                <td style="width:40%"><b>Level of education</b></td>
                                                <td v-if="viewData?.educationalLevel!==undefined">
                                                    <span v-if="viewData.educationalLevel!==null">
                                                        {{viewData.educationalLevel.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td> 
                                            </tr>
                                            <tr> 
                                                <td><b>Size of Family</b></td>
                                                <td v-if="viewData?.familySize!==undefined">
                                                    <span v-if="viewData.familySize!==null">
                                                        {{viewData.familySize.range}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td> 
                                            </tr>
                                            <tr> 
                                                <td><b>Access to Healthcare</b></td>
                                                <td>
                                                    {{(viewData.healthcareAccess==true)? 'Yes' : 'No' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Access to Education</b></td>
                                                <td>
                                                    {{(viewData.educationAccess==true)? 'Yes' : 'No' }}
                                                </td>
                                            </tr>
                                            <tr> 
                                                <td><b>Access to Potable Water</b></td>
                                                <td>
                                                    {{(viewData.waterAccess==true)? 'Yes' : 'No' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Access to Electricty</b></td>
                                                <td>
                                                    {{(viewData.electricityAccess==true)? 'Yes' : 'No' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Children attend school</b></td>
                                                <td>
                                                    {{(viewData.childrenAttendSchool==true)? 'Yes' : 'No' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>House Owner</b></td>
                                                <td>
                                                    {{(viewData.homeOwner==true)? 'Yes' : 'No' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Other Information -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5">
                                        <i class="fas fa-book"></i> Other Info
                                    </h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr> 
                                                <td style="width:40%"><b>Business Challenge</b></td>
                                                <td v-if="viewData?.businessChallenge!==undefined">
                                                    <span v-if="viewData.businessChallenge!==null">
                                                        {{viewData.businessChallenge.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td>. . .</td>
                                            </tr>
                                            <tr> 
                                                <td><b>Challenge in the Locality</b></td>
                                                <td v-if="viewData?.localityChallenge!==undefined">
                                                    <span v-if="viewData.localityChallenge!==null">
                                                        {{viewData.localityChallenge.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td> 
                                            </tr>
                                            <tr> 
                                                <td><b>Benefited from Govt. Intervention</b></td>
                                                <td v-if="viewData.govtIntervention!==undefined">
                                                    <span v-if="viewData.govtIntervention!==null">
                                                        {{viewData.govtIntervention.name}}
                                                    </span>
                                                    <span v-else>. . .</span>                                                  
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Received Govt. Palliantive</b></td>
                                                <td v-if="viewData.govtIntervention!==undefined">
                                                    <span v-if="viewData.govtIntervention!==null">
                                                        {{viewData.govtIntervention.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr> 
                                                <td><b>Preferred Palliative</b></td>
                                                <td v-if="viewData.palliative!==undefined">
                                                    <span v-if="viewData.palliative!==null">
                                                        {{viewData.palliative.name}}
                                                    </span>
                                                    <span v-else>. . .</span>
                                                </td>
                                                <td v-else>. . .</td>
                                            </tr>
                                            <tr>
                                                <td><b>Request to Govt.</b></td>
                                                <td>{{(viewData.requestToGovt!==null)? viewData.requestToGovt : '. . .'}}</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Geo Location -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-map-marker-alt"></i> Geo Location</h6>
                                </div>
                                <template v-if="is_data_fm&&!isLoading">
                                    <div class="text-uppercase">
                                        <h6 class="font-weight-bolder text-sm mt-5">Manual GPS :</h6>
                                    </div>
                                    <div class="mt-3">
                                        <div class="table-responsive p-0">
                                            <table class="table align-items-center mb-0">
                                                <tbody>
                                                    <tr> 
                                                        <td style="width:40%"><b> Longitude</b></td>
                                                        <td>
                                                            <span v-if="pre_process_cords_view.staus!==false&&pre_process_cords_view.process.manual.lng!==null">
                                                                {{pre_process_cords_view.process.manual.lng}}
                                                            </span>
                                                            <span v-else>
                                                                ...
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr> 
                                                        <td><b> Latitude</b></td>
                                                        <td>
                                                            <span v-if="pre_process_cords_view.status!==false&&pre_process_cords_view.process.manual.lat!==null">
                                                                {{pre_process_cords_view.process.manual.lat}}
                                                            </span>
                                                            <span v-else>
                                                                ...
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr> <td><b> Adjustments</b></td><td>...</td> </tr> 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>                                   
                                    <div class="text-uppercase">
                                        <h6 class="font-weight-bolder text-sm mt-5">Auto GPS :</h6>
                                    </div>
                                </template>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                            <tbody v-if="!isLoading">
                                                <tr> 
                                                    <td style="width:40%"><b> Longitude</b></td>
                                                    <td>
                                                        <span v-if="pre_process_cords_view.staus!==false">
                                                            {{pre_process_cords_view.process.lng}}
                                                        </span>
                                                        <span v-else>
                                                            ...
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr> 
                                                    <td><b> Latitude</b></td>
                                                    <td>
                                                        <span v-if="pre_process_cords_view.status!==false">
                                                            {{pre_process_cords_view.process.lat}}
                                                        </span>
                                                        <span v-else>
                                                            ...
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr> <td><b> Adjustments</b></td><td>...</td> </tr> 
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td><b> Coordinates</b></td>
                                                    <td>... Loading</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Geo Location -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-map"></i> Maps</h6>
                                </div>
                                <div class="mt-3" v-if="viewData.gpsLocation!==null">
                                    <div class="py-2 small" v-if="is_map_polygon&&is_map_active">
                                        <b>Farm Area :</b> {{ calculatePolygonArea(polygon.paths,'hecters')  }}
                                    </div>
                                    <GoogleMap v-if="is_map_active==true" :api-key="API_KEY" style="width: 100%; height: 500px; border-radius:9px;" :center="center" :zoom="(is_map_polygon==true)? 20 : 15">
                                        <Marker v-if="is_map_polygon==false" :options="{ position: center }" />
                                        <Polygon v-else :options="polygon"></Polygon>
                                    </GoogleMap>
                                </div>
                                <div class="mt-3" v-else>
                                    ...
                                </div>
                            </div>
                        </div>
                        <div class="spinner-holder" v-else-if="isLoading==true && message==''">
                            <vsud-spinner></vsud-spinner>
                        </div>
                        <div class="nodata-data" v-else>
                            <vsud-nodata :message="message"></vsud-nodata>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import imgDef from "@/assets/img/team-5.png";
import VsudBadge from "@/components/VsudBadge.vue";
import Helper from '@/helpers/generalHandlers.js';
import moment from "moment";
import { GoogleMap, Marker, Polygon } from "vue3-google-map";

export default {
    name : "view-beneficiary",
    data()
    {
        return {
            id : this.$route.params.id,
            title : this.subTitle,
            imgDef,
            viewData : [],
            isLoading : false,
            isSubmitted : false,
            isSubmitLoading : false,
            message : '',
            alertMessage : '',
            alertColor : 'success',
            type : this.formType,
            userClass : '',
            isAdminUser : false,
            is_map_polygon : false,
            is_map_active : false,
            is_data_fm : false,
            pre_process_cords_view : {},
            center : {},
            polygon : {},
            API_KEY : process.env.VUE_APP_GOOGLE_APIKEY
        }
    },

    components : 
    {
        VsudButton,
        VsudSpinner,
        VsudSpinnerSmall,
        VsudNodata,
        VsudAlert,
        VsudBadge,
        GoogleMap, 
        Marker,
        Polygon
    },

    created()
    {
        this.getDetails();
        this.moment = moment;
    },

    methods : 
    {
        async getDetails()
        {
            this.isLoading = true;

            try
            {
                const response = await AuthService.getSingleBenficiary(this.id);
                this.viewData = response.data;
                this.is_data_fm = false;
                var cords_data = response.data.gpsLocation;

                if(this.is_data_fm&&response.data.manualGps!==null)
                {
                    if(response.data.manualGps[0].latitude!==null&&response.data.manualGps[0].longitude!==null)
                    {
                        cords_data = response.data.manualGps;
                    }
                }
                
                let cordinates = this.is_cord_polygon(cords_data);
                this.center = cordinates.center;
                this.polygon = cordinates.polygon;
                this.preProcessCords();
                this.isLoading = false;
                
                console.log(this.viewData);
            }
            catch(error)
            {
                console.log(error);
                this.isLoading = false;
                this.message = error.message
            }

        },

        getUserClass()
        {
            if(this.$store.getters.isLoggedIn)
            {
                this.userClass = this.$store.getters.getUser.primaryRole.name;
            }
        },
        
        cleanImg(img)
        {
            if(img&&img!==null&&img!=='')
            {
                return img;
            }
            
            return '';
        },

        is_cord_polygon(data)
        {
            var centre_data  = {};
            var polygon_data = {};

            if(data!==null)
            {
                var latitude  = data[0].latitude;
                var longitude = data[0].longitude;
                var str_limit = 20;

                if(parseFloat(latitude)&&parseFloat(longitude))
                {
                    centre_data = { lat: parseFloat(latitude), lng: parseFloat(longitude) }
                    this.is_map_active = true;
                }
                else if((latitude!==''&&latitude!==null)||(longitude!==''&&latitude!==null))
                {
                    // Check if Polygon Exist
                    var gps_check = Helper.checkGpsExist(data[0]);
                    
                    if(gps_check.is_polygon==true)
                    {
                        polygon_data = {
                                paths: [],
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                        }

                        const findCenters = arr => {
                            
                            var minX, maxX, minY, maxY;

                            for (var i = 0; i < arr.length; i++)
                            {
                                minX = (arr[i].lat < minX || minX == null) ? arr[i].lat : minX;
                                maxX = (arr[i].lat > maxX || maxX == null) ? arr[i].lat : maxX;
                                minY = (arr[i].lng < minY || minY == null) ? arr[i].lng : minY;
                                maxY = (arr[i].lng > maxY || maxY == null) ? arr[i].lng : maxY;
                            }

                            return { lat : (minX + maxX) / 2, lng : (minY + maxY) / 2 };
                        }

                        var activate_polygon = false;

                        var get_cords_values = (data) => {

                            let text = data;
                            let splitText = text.split(' ').join('');
                            const myArray = splitText.split(',');

                            let triangleCoords = [];
                            let temp  = 0;
                            let cords = 0;
                            let lnt = myArray.length;

                            while(temp<lnt)
                            {
                                var obj = {lat:0, lng : 0};
                                var pnt1 = temp;
                                var pnt2 = pnt1 + 1;
                                var splitArray1 = myArray[pnt1].split(':');
                                
                                if(splitArray1)
                                {
                                    if(splitArray1[0]=='Latitude'&&splitArray1[1]!==undefined&&splitArray1[1]!=='')
                                    {
                                        obj.lat = parseFloat(splitArray1[1]);
                                    }
                                    else if(splitArray1[0]=='Longitude'&&splitArray1[1]!==undefined&&splitArray1[1]!=='')
                                    {
                                        obj.lng = parseFloat(splitArray1[1]); 
                                    }
                                    
                                    var splitArray2 = myArray[pnt2].split(':');
                                    
                                    if(splitArray2)
                                    {
                                        if(splitArray2[0]=='Longitude')
                                        {
                                            obj.lng = parseFloat(splitArray2[1]);
                                        }
                                        else if(splitArray2[0]=='Latitude')
                                        {
                                            obj.lat = parseFloat(splitArray2[1]); 
                                        }
                                    }
                                    
                                    triangleCoords[cords] = obj;
                                    
                                    cords++;
                                }
                                
                                temp = temp + 2;
                            }

                            return triangleCoords;
                        }

                        if(latitude.length>str_limit)
                        {
                            polygon_data.paths  = get_cords_values(latitude);
                            activate_polygon = true;
                        }
                        else if(longitude.length>str_limit)
                        {
                            polygon_data.paths = get_cords_values(longitude);
                            activate_polygon = true;
                        }

                        if(activate_polygon==true)
                        {
                            this.is_map_polygon = true;
                            this.is_map_active  = true;
                            centre_data = findCenters(polygon_data.paths);
                        }
                    }
                    else
                    {
                        if(gps_check.polygon_count==1)
                        {
                            if(gps_check.two_points.lat!==0||gps_check.two_points.lng!==0)
                            {
                                centre_data = { lat : gps_check.two_points.lat, lng : gps_check.two_points.lng }
                                this.is_map_active = true;
                            }
                        }
                    }
                }
            }

            return { center : centre_data, polygon : polygon_data };
        },

        calculatePolygonArea(coords,unit) 
        {
            let area = 0;

            for (let i = 0; i < coords.length; i++) 
            {
                const [x1, y1]  = [coords[i].lat, coords[i].lng];
                
                let temp_coords = coords[(i + 1) % coords.length];
                const [x2, y2]  = [temp_coords.lat, temp_coords.lng];

                area += x1 * y2 - x2 * y1
            }
            
            var total_area = Math.abs(area) / 2;

            switch(unit)
            {
                case 'hecters' : 
                    var unit_ha = 10000;
                    total_area  = (total_area * unit_ha).toFixed(6) + ' Ha'; 
                break;

                default : total_area = total_area;
            }

            return total_area;
        },

        preProcessCords()
        {
            var is_manual_active = false;
            var cords_data = null;

            if(this.viewData.gpsLocation!==null)
            {
                cords_data = {
                    lat : this.viewData.gpsLocation[0].longitude,
                    lng : this.viewData.gpsLocation[0].latitude,
                    manual : { lat : null, lng : null}
                }
            }

            if(this.is_data_fm)
            {
                if(this.viewData.manualGps!==null)
                {
                    if(this.viewData.manualGps[0].latitude!==null&&this.viewData.manualGps[0].longitude!==null)
                    {
                        if(cords_data==null)
                        {
                            cords_data = { 
                                lat : null, 
                                lng : null, 
                                manual : { lat : null, lng : null} 
                            }
                        }

                        cords_data.manual.lat = this.viewData.manualGps[0].latitude;
                        cords_data.manual.lng = this.viewData.manualGps[0].longitude;
                        is_manual_active = true;
                    }
                }
            }

            var data = {
               gps_manual : is_manual_active,
               is_mapactive : this.is_map_active,
               is_polygon : this.is_map_polygon,
               center : this.center,
               cords : cords_data 
            }

            this.pre_process_cords_view = Helper.preProcessCoordView(data)
        },

        renderStatus(status, updated, type='status')
        {
            let text  = 'Enumerated'; 
            let color = 'success';

            if(status=='inactive')
            {
                color = 'danger'; text = 'In-active'
            }

            if(updated===true)
            {
                text = 'Updated'; color = 'info';
            }

            return (type=='status')? text : color;
        }
    }
};
</script>
