<template>
    <timeline-list>
        <timeline-item 
        v-for="item in listData"
        :key="item.id"
        :color="getBadge(item.type)"
        icon="bell-55"
        :userId="item.id"
        :imgUrl="cleanImg(item.photo_link)"
        :title="item.name"
        :date-time="item.updated_at"
        :badges="[item.type, item.area]"
        />
    </timeline-list>
</template>

<script>
import TimelineList from "@/views/components/dashboard/UpdatedBeneficiaries/List.vue";
import TimelineItem from "@/views/components/dashboard/UpdatedBeneficiaries/Item.vue";

export default
{
    name : "updated-beneficiary-layout",
    components : {
        TimelineList,
        TimelineItem,
    },
    props : {
        listData : {
            type : Array,
            default : []
        }
    },

    methods :
    {
        getBadge(type)
        {
            var data = '';

            switch(type)
            {
                case 'new' : data = 'success';break;
                case 'status'  : data = 'warning';break;
                case 'updated' : data = 'info';break;
                default : data = 'secondary';
            }

            return data;
        },
        
        cleanImg(img)
        {
            if(img!==null&&img!=='')
            {
                return img;
            }

            return '';
        }
    },
}
</script>