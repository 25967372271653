<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                    <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                            <h6><i class="fas fa-search"></i> Search Filter</h6>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div 
                                        class="input-group-text" 
                                        style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                                        Report type &nbsp; <i class="fa fa-chart-area"></i>
                                    </div>
                                </div>
                                <select name="report-type" class="form-control" id="inlineFormInputGroup" v-model="report_type">
                                    <option value="general-report"> &nbsp; General Reports</option>
                                    <!--                                           
                                    <option value="enum-activity"> &nbsp; Enumerator Activity</option>
                                    <option value="lga-enum-activity"> &nbsp; LGA Enumeration Activity</option>
                                    -->
                                </select>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-4 px-3 text-xs">
                        &nbsp; <span class="text-danger">*</span> Kindly choose report type to generate and view other reports 
                    </div>
                </div>
                <div class="reports-holder-parent">
                    <general-reports v-if="report_type=='general-report'"></general-reports>
                    <enumerator-activity v-else-if="report_type=='enum-activity'"></enumerator-activity>
                    <lga-enumeration-activity v-else-if="report_type=='lga-enum-activity'"></lga-enumeration-activity>
                    <state-enumeration-activity v-else-if="report_type=='state-enum-activity'"></state-enumeration-activity>
                    <geo-enumeration-activity v-else-if="report_type=='geo-enum-activity'"></geo-enumeration-activity>
                    <country-enumeration-activity v-else-if="report_type=='country-enum-activity'"></country-enumeration-activity>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralReports from "@/views/reports/enumeration/General.vue";
import EnumeratorActivity from "@/views/reports/enumeration/EnumeratorActivity.vue";
import LgaEnumerationActivity from "@/views/reports/enumeration/LgaEnumerationActivity.vue";
import StateEnumerationActivity from  "@/views/reports/enumeration/StateEnumerationActivity.vue";
import GeoEnumerationActivity from "@/views/reports/enumeration/GeoEnumerationActivity.vue";
import CountryEnumerationActivity from "@/views/reports/enumeration/CountryEnumerationActivity.vue";

export default {
  name: "main-reports-page",
  data()
  {
    return {
      report_type : 'general-report'
    }
  },

  components: 
  {
    GeneralReports,
    EnumeratorActivity,
    LgaEnumerationActivity,
    StateEnumerationActivity,
    GeoEnumerationActivity,
    CountryEnumerationActivity
  },

  created()
  {
    
  },

  methods :
  {

  }
};
</script>
