import { createRouter, createWebHistory } from "vue-router";
// General Components
import Dashboard from "@/views/Dashboard.vue";
import Profile from "@/views/Profile.vue";

// Users Components
import UserList from "@/views/users/ListUser.vue";
import UserAdd from "@/views/users/Add.vue";
import UserEdit from "@/views/users/Edit.vue";

// Schemes Components
import ListScheme from "@/views/scheme/List.vue";
import AddScheme from "@/views/scheme/Add.vue";
import EditScheme from "@/views/scheme/Edit.vue";

// Disability Components
import ListDisability from "@/views/disabilities/List.vue";
import AddDisability from "@/views/disabilities/Add.vue";
import EditDisability from "@/views/disabilities/Edit.vue";

// Crop Type Components
import ListCroptype from "@/views/croptype/List.vue";
import AddCroptype from "@/views/croptype/Add.vue";
import EditCroptype from "@/views/croptype/Edit.vue";

// Trade Type Components
import ListTradetype from "@/views/tradetype/List.vue";
import AddTradetype from "@/views/tradetype/Add.vue";
import EditTradetype from "@/views/tradetype/Edit.vue";

// Trade Type Components
import ListBusinessLocation from "@/views/businesslocation/List.vue";
import AddBusinessLocation from "@/views/businesslocation/Add.vue";
import EditBusinessLocation from "@/views/businesslocation/Edit.vue";

// State Components
import ListState from "@/views/state/List.vue";
import AddState from "@/views/state/Add.vue";
import EditState from "@/views/state/Edit.vue";

// Consultant Components
import ListConsultant from "@/views/consultants/List.vue";
import AddConsultant from "@/views/consultants/Add.vue";
import EditConsultant from "@/views/consultants/Edit.vue";

// Local Gov Components
import ListLocalGov from "@/views/localgov/List.vue";
import AddLocalGov from "@/views/localgov/Add.vue";
import EditLocalGov from "@/views/localgov/Edit.vue";

// Ward Components
import ListWard from "@/views/ward/List.vue";
import AddWard from "@/views/ward/Add.vue";
import EditWard from "@/views/ward/Edit.vue";

// Beneficiary Components
import ListBeneficiary from "@/views/beneficiaries/List.vue";
import ViewBeneficiary from "@/views/beneficiaries/View.vue";

// Registration Components
import RegistrationList from "@/views/registration/List.vue";
import ViewRegistered from "@/views/registration/View.vue";

// Loan Components
import LoanList from "@/views/loan/List.vue";
import ViewLoan from "@/views/loan/View.vue";

// Reports
// - General Reports
import GeneralReports from "@/views/reports/enumeration/Main.vue";
import RegistrationReports from "@/views/reports/registration/Main.vue";
import LoanReports from "@/views/reports/loan/Main.vue";
import StateCount from "@/views/reports/StateCountEnum.vue";
import ActivityCount from "@/views/reports/ActivityCount.vue";
import StateCountLoan from "@/views/reports/StateCountLoan.vue";
import StateCountRegiration from "@/views/reports/RegistrationCount.vue";
import AreaCount from "@/views/reports/AreaCount.vue";

// Login Component
import LogIn from "@/views/auth/LoginPage.vue";
// 403
import NotAuthorized from "@/views/auth/NotAuhtorized.vue";
// Auth Interceptor
import Auth from "@/services/auth.interceptor.js";

const routes = [

  /**
   * General Routes 
   */
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: 'Skills - MyApp' }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: LogIn
  },

  /**
   * Users Route 
   * - Points @ : List, Add, Edit
   */
  {
    path: "/user",
    name: "Users",
    component : UserList,
    children : [
      {
        name : "Users",
        path: "back/:status",
        component : UserList
      }
    ]
  },
  {
    path: "/user/edit/:id",
    name: "Edit User",
    component : UserEdit
  },
  {
    path: "/user/add",
    name: "Add User",
    component : UserAdd
  },

  /**
   * Scheme Routes 
   * - Points @ : List, Add, Edit
   */
   {
    path: "/scheme",
    name: "Schemes",
    component : ListScheme,
    children : [
      {
        name : "Schemes",
        path: "back/:status",
        component : ListScheme
      }
    ]
  },
  {
    path: "/scheme/edit/:id",
    name: "Edit Scheme",
    component : EditScheme
  },
  {
    path: "/scheme/add",
    name: "Add Scheme",
    component : AddScheme
  },

  /**
   * Disabilities Routes 
   * - Points @ : List, Add, Edit
   */
  {
    path: "/disability",
    name: "Disabilities",
    component : ListDisability,
    children : [
      {
        name : "Disabilities",
        path: "back/:status",
        component : ListDisability
      }
    ]
  },
  {
    path: "/disability/edit/:id",
    name: "Edit Disability",
    component : EditDisability
  },
  {
    path: "/disability/add",
    name: "Add Disability",
    component : AddDisability
  },

  /**
   * Crop Type Routes 
   * - Points @ : List, Add, Edit
   */
  {
    path: "/croptype",
    name: "Crop Types",
    component : ListCroptype,
    children : [
      {
        name : "Crop Types",
        path: "back/:status",
        component : ListCroptype
      }
    ]
  },
  {
    path: "/croptype/edit/:id",
    name: "Edit Crop Type",
    component : EditCroptype
  },
  {
    path: "/croptype/add",
    name: "Add Crop Type",
    component : AddCroptype
  },

  /**
   * Crop Type Routes 
   * - Points @ : List, Add, Edit
   */
  {
    path: "/tradetype",
    name: "Trade Types",
    component : ListTradetype,
    children : [
      {
        name : "Trade Types",
        path : "back/:status",
        component : ListTradetype
      }
    ]
  },
  {
    path: "/tradetype/edit/:id",
    name: "Edit Trade Type",
    component : EditTradetype
  },
  {
    path: "/tradetype/add",
    name: "Add Trade Type",
    component : AddTradetype
  },

  /**
   * Business Location Routes 
   * - Points @ : List, Add, Edit
   */
   {
    path: "/businesslocation",
    name: "Business Locations",
    component : ListBusinessLocation,
    children : [
      {
        name : "Business Locations",
        path : "back/:status",
        component : ListBusinessLocation
      }
    ]
  },
  {
    path: "/businesslocation/edit/:id",
    name: "Edit Business Location",
    component : EditBusinessLocation
  },
  {
    path: "/businesslocation/add",
    name: "Add Business Location",
    component : AddBusinessLocation
  },

  /**
   * State Routes 
   * - Points @ : List, Add, Edit
   */
   {
    path: "/states",
    name: "States",
    component : ListState,
    children : [
      {
        name : "States",
        path : "back/:status",
        component : ListState
      },
      {
        name : "States",
        path : "pages/:pgnumber",
        component : ListState
      }
    ]
  },
  {
    path: "/state/edit/:id",
    name: "Edit State",
    component : EditState
  },
  {
    path: "/state/add",
    name: "Add State",
    component : AddState
  },

  /**
   * Consultants Routes 
   * - Points @ : List, Add, Edit
   */
   {
    path: "/consultants",
    name: "Consultants",
    component : ListConsultant,
    children : [
      {
        name : "Consultants",
        path : "back/:status",
        component : ListConsultant
      }
    ]
  },
  {
    path: "/consultant/edit/:id",
    name: "Edit Consultant",
    component : EditConsultant
  },
  {
    path: "/consultant/add",
    name: "Add Consultant",
    component : AddConsultant
  },

  /**
   * Local Governments 
   * - Points @ : List, Add, Edit
   */
   {
    path: "/local-governments",
    name: "Local Governments",
    component : ListLocalGov,
    children : [
      {
        name : "Local Governments",
        path : "back/:status",
        component : ListLocalGov
      }
    ]
  },
  {
    path: "/local-governments/edit/:id",
    name: "Edit Local Government",
    component : EditLocalGov
  },
  {
    path: "/local-governments/add",
    name: "Add Local Government",
    component : AddLocalGov
  },

  /**
   * Wards 
   * - Points @ : List, Add, Edit
   */
   {
    path: "/wards",
    name: "Wards",
    component : ListWard,
    children : [
      {
        name : "Wards",
        path : "back/:status",
        component : ListWard
      }
    ]
  },
  {
    path: "/ward/edit/:id",
    name: "Edit Ward",
    component : EditWard
  },
  {
    path: "/ward/add",
    name: "Add Ward",
    component : AddWard
  },

 /**
  * Beneficiaries
  * - Points @ : List, Add, Edit
  */
  {
    path: "/beneficiaries",
    name: "Beneficiaries",
    component : ListBeneficiary,
    children : [
      {
        name : "Beneficiaries",
        path : "back/:status",
        component : ListBeneficiary
      },
      {
        name : "Beneficiaries",
        path : "pages/:pgnumber",
        component : ListBeneficiary
      }
    ]
  },
  {
    path: "/beneficiary/view/:id",
    name: "View Beneficiary",
    component : ViewBeneficiary
  },

  
  /**
  * Registrations
  * - Points @ : List, Add, Edit
  */
   {
    path: "/registration",
    name: "Registration",
    component : RegistrationList,
    children : [
      {
        name : "Registration",
        path : "back/:status",
        component : RegistrationList
      },
      {
        name : "Registrations",
        path : "pages/:pgnumber",
        component : RegistrationList
      }
    ]
  },
  {
    path: "/registration/view/:id",
    name: "View Registered Person",
    component : ViewRegistered
  },

 /**
  * Loans
  * - Points @ : List, Add, Edit
  */
    {
      path: "/loans",
      name: "Loan Beneficiary List",
      component : LoanList,
      children : [
        {
          name : "Loan Beneficiary List",
          path : "back/:status",
          component : LoanList
        },
        {
          name : "Loan Beneficiary List",
          path : "pages/:pgnumber",
          component : LoanList
        }
      ]
    },
    {
      path: "/loan/view/:id",
      name: "View Loan Beneficiary",
      component : ViewLoan
    },

  /**
   * Reports 
   */
  {
    path: "/reports/enumeration",
    name: "Enumeration Reports",
    component : GeneralReports
  },
  {
    path: "/reports/registration",
    name: "Registration Reports",
    component : RegistrationReports
  },
  {
    path: "/reports/loan",
    name: "Loan Reports",
    component : LoanReports
  },
  {
    path: "/reports/general/state/count",
    name: "State Enumeration Activity",
    component : StateCount
  },
  {
    path: "/reports/all/enumeration-activity",
    name: "Enumeration Activity",
    component : ActivityCount
  },
  {
    path: "/reports/general/state/count/loan",
    name: "Loan State Disbursement Activity",
    component : StateCountLoan
  },
  {
    path: "/reports/general/state/count/registration/:year",
    name: "Registration Activity",
    component : StateCountRegiration
  },
  {
    path: "/reports/general/area/count",
    name: "Area Enumeration Activity",
    component : AreaCount
  },



 /**
  * Not Authorized
  * - Points @ : *
  */
  {
    path: "/error/not-authorized",
    name: "Not-Authorized",
    component: NotAuthorized
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to,from)=>{
  
  const canAccess = Auth.canUserAccess(to.name);
  // # Debug
  // console.log('# Debug can access :')
  // console.log(canAccess);

  if (canAccess=='back-to-login')
  { 
    return '/auth/login';
  }
  else if(canAccess=='not-authorized')
  {
    return '/error/not-authorized';
  }

});


export default router;
