import store from "@/store";

export default 
{

  canUserAccess(routeName)
  { 
    const userIsLoggedIn = this.beforeRouteEnter(store.getters.isLoggedIn);
    const exception = (routeName!=='Login')? false : true;
    var data = 'access-granted';

    // Check tokke expiration
    if(!exception&&!userIsLoggedIn) 
    {
      data = 'back-to-login';
    }
    else
    {
      if(userIsLoggedIn)
      {
        const userClass = store.getters.getUser.primaryRole.name;
        
        if(userClass=='admin'||userClass=='enumerator'||userClass=='api'||userClass=='focal_manager'||userClass=='registration_admin')
        {
          var access = this.userPermissions(userClass,routeName);

          if(access==false)
          {
            data = 'not-authorized';
          }
        }
      }
    }

    return data;
  },

  userPermissions(user,route) 
  {
      let permissions = [ '/','Dashboard','Beneficiaries','View Beneficiary','Profile','Logout','Reports','Not-Authorized'];
      var access = false;

      switch(user)
      {
        case 'sample':
          permissions.push('Users', 'Add User', 'Edit User', 'Enumeration Reports', 'Enumeration Activity', 'State Enumeration Activity');
        break;

        case 'enumerator' : case 'admin' : case 'api' : case 'focal_manager' : case 'registration_admin' :
          permissions = ['/','Not-Authorized'] ;
        break;
      }

      for(var i=0; i<permissions.length; i++)
      {
        if(permissions[i]==route)
        {
            access = true;
        }
      }

      return access;
  },

  parseJwt (token) 
  {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
  },

  beforeRouteEnter (token) 
  {
      if(token) 
      {
        const jwtPayload = this.parseJwt(token);
        
        if (jwtPayload.exp < Date.now()/1000) 
        {
            return false;
        }
        else
        {
            return true;
        }
      } 

      return false;
  }
}