<template>
  <div class="mb-3 timeline-block" :class="darkMode">
    <span class="timeline-step" style="width: 41px;" :class="darkMode ? 'bg-dark' : ''">
      <!--
      <i class="ni text-gradient" :class="`ni-${icon} text-${color}`"></i>
      -->
      <img :style="styleImg(true)" :src="imgUrl" alt="team2" v-if="imgUrl!==null&&imgUrl!==''" />
      <img :style="styleImg()" :src="imgDef" alt="team2" v-else/>
    </span>
    <div class="timeline-content" style="top: -14px;">
      <h6
        class="mb-0 text-xs font-weight-bold"
        :class="darkMode ? 'text-white' : 'text-dark'">
        {{ title }}
      </h6>
      <p class="mt-1 mb-0 text-xxs text-secondary font-weight-bold">
        <b>Timestamp : </b>{{ moment(dateTime).format("ddd MMM DD, YYYY [at] HH:mm a") }}
      </p>
      <p v-if="description" class="mt-3 mb-2 text-sm">
        {{ description }}
      </p>
      <span
        v-for="(badge, index) of badges"
        :key="index"
        style="font-size : 0.55em;"
        class="badge badge-sm me-1"
        :class="`bg-gradient-${color}`"
      >
        {{ badeName(badge) }}
      </span>
      <span>
        <router-link
          class="text-secondary font-weight-bold text-xs"
          data-toggle="tooltip"
          data-original-title="View Beneficiary"
          target="_blank"
          :to="{name:'View Beneficiary' , params: {id:userId}}">
          <i class="fa fa-eye"></i> View
        </router-link>
      </span>
    </div>
  </div>
</template>
<script>
import imgDef from "@/assets/img/team-5.png";
import moment from "moment";

export default {
  name: "UpdateBeneficiariesList",
  props: {
    color: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    userId: 
    {
      type: String,
      default:"",
    },
    title: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    badges: {
      type: Array,
      default: () => [],
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    imgUrl : {
      type: String,
      default : imgDef
    }
  },
  created(){
    this.moment = moment;
  },

  methods : 
  {
     styleImg(border=false)
     { 
       var addborder = (border===true)? 'border : 2px solid #CCC' : '';

       return 'width:41px;height:41px;border-radius: 50% !important;'+addborder;
     },

     badeName(data)
     {  
        let response = '';

        if(data==null)
        {
           response = 'Suleija';
        }
        else
        {
           if(data.name!==undefined)
           {
            response = data.name;
           }
           else
           {
             response = data;
           }
        }

        return response;
     }
  },
  data(){
      return {
        imgDef
      }
  }
};
</script>
