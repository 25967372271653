<template>
    <div class="lint-one-element">
        <loader v-if="isSubmitLoading" style="z-index:10000" object="#344767" color1="#ffffff" color2="#2152ff" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <vsud-alert
            v-if="isSubmitted"
            icon="fa fa-bell"
            :dismissible="true"
            :color="alertColor">
            {{ alertMessage }}
        </vsud-alert>
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                <div class="col-md-8 d-flex align-items-center">
                    <h6>{{ title }}</h6>
                </div>
                <div class="col-md-4 text-end">
                    <router-link :to="{ name: 'Users' }">
                        Close <i class="fas fa-times-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Cancel" aria-label="Cancel"></i><span class="sr-only">Cancel</span>
                    </router-link>
                </div>
                </div>
            </div>
            <div class="card-body p-4">
                <div class="users-data" v-if="isLoading==false && message==''">
                    <form @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Name <span class="text-danger">*</span></label>
                                <div>
                                    <input type="text" name="name" class="form-control" placeholder="Please full name"  v-model="name" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Email <span class="text-danger">*</span></label>
                                <div>
                                <input type="text" name="email" class="form-control" placeholder="Please enter valid email"  v-model="email" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Phone No. <span class="text-danger"></span></label>
                                <div>
                                <input type="text" name="phone" class="form-control" placeholder="Please enter valid phone no."  v-model="phone" />
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Role <span class="text-danger">*</span></label>
                                &nbsp;
                                <vsud-spinner-small v-if="isRoleLoading"></vsud-spinner-small>
                                <select name="role" class="form-control" v-model="role_id"  required>
                                    <option value="" :selected="true">... Select </option>
                                    <template v-if="userClass=='enumeration_admin'||userClass=='registration_admin'">
                                        <template v-for="item in roles" >
                                            <option 
                                                :value="item.id" 
                                                :key="item.id" 
                                                v-if="item.name!=='superadmin'&&item.name!='project_owner'&&item.name!='executive'&&item.name!='bank'">
                                                <span v-if="item.name=='supervisor'">state_supervisor</span>
                                                <span v-else>{{item.name}}</span>
                                            </option>
                                        </template>
                                    </template>
                                    <template v-else-if="userClass=='superadmin'">
                                        <template  v-for="item in roles">
                                            <option 
                                                :value="item.id"
                                                v-if="item.name!=='superadmin'&&item.name!=='api'&&item.name!=='focal_manager'&&item.name!=='registration_admin'"
                                                :key="item.id">
                                                <span v-if="item.name=='supervisor'">state_supervisor</span>
                                                <span v-else>{{item.name}}</span>
                                            </option>
                                        </template>
                                    </template>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3" v-if="!isAdminUser">
                                <label>Consultants <span class="text-danger"></span></label>
                                <select name="role" class="form-control" v-model="consultant_id"  :required="isAdminUser">
                                <option value="" :selected="true">... Select </option>
                                <option 
                                    v-for="item in consultants"
                                    :value="item.id" 
                                    :key="item.id">
                                    {{item.name}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>
                                    States <span class="text-danger" v-if="isAdminUser">*</span>
                                    &nbsp;
                                    <vsud-spinner-small v-if="isStateLoading"></vsud-spinner-small>
                                </label>
                                <select name="role" class="form-control" v-model="state_id" @change="onChangeState($event)"  :required="isAdminUser" disabled>
                                <option value="" :selected="true">... Select </option>
                                <option 
                                    v-for="(item,index) in states"
                                    :value="item.id"
                                    :key="index">
                                    {{item.name}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Local Governments <span class="text-danger"></span></label>
                                <select name="role" class="form-control" v-model="local_government_id" @change="onChangeLocalGov($event)" :disabled="!this.lg_ck.length>0">
                                <option value="" :selected="true">... Select ( Optional ) </option>
                                <option 
                                    v-for="(item, index) in lg_ck"
                                    :value="item.id" 
                                    :key="index">
                                    {{item.name}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Wards<span class="text-danger"></span></label>
                                <select name="role" class="form-control" v-model="ward_id" :disabled="!this.wd_ck.length>0">
                                <option value="" :selected="true">... Select ( Optional ) </option>
                                <option 
                                    v-for="item in wd_ck"
                                    :value="item.id" 
                                    :key="item.id">
                                    {{item.name}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-3">
                                <label>Password <span class="text-info" title="Default password is always 1-9 but you can change it if you wish here"><i class="fas fa-info-circle"></i></span></label>
                                <div>
                                    <input v-model="password" type="text" name="password" class="form-control" placeholder="Default password is 1 to 9" >
                                </div>
                            </div>
                            <div class="col-md-12 mt-4 geep-text-right">
                                <VsudButton color="success" variant="gradient"> Submit </VsudButton>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="spinner-holder" v-else-if="isLoading==true && message==''">
                    <vsud-spinner></vsud-spinner>
                </div>
                <div class="nodata-data" v-else>
                    <vsud-nodata :message="message"></vsud-nodata>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';

export default {
  name: "users-form",

  props : 
  {
      userId : {
          type : String,
          default : '',
      },
      subTitle : {
          type : String,
          default : 'Add User'
      },
      formType :{
          type : String,
          default : 'Add'
      }
  },

  data()
  {
     return {
         id : this.userId,
         title : this.subTitle,
         name : '',
         email : '',
         phone : '',
         role_id : '',
         consultant_id : '',
         local_government_id : '',
         consultant_id : '',
         states : [],
         state_id : '',
         isStateLoading : false,
         ward_id : '',
         roles : [],
         isRoleLoading : false,
         consultants : [],
         local_governments : [],
         lg_ck : [],
         wd_ck : [],
         wards : [],
         password : '',
         isLoading : false,
         isSubmitted : false,
         isSubmitLoading : false,
         message : '',
         alertMessage : '',
         alertColor : 'success',
         type : this.formType,
         userClass : '',
         isAdminUser : false,
     }
  },

  components : 
  {
      VsudButton,
      VsudSpinner,
      VsudSpinnerSmall,
      VsudNodata,
      VsudAlert,
  },

  created()
  {
      this.getUserClass();
      this.States();
      this.getUserDetils();
      this.Roles();
      this.Consultants();
      this.LocalGovernments();
      this.Wards();
  },

  methods : 
  {
      async getUserDetils()
      {
        console.log(this.id);
        console.log(this.isLoading);

        if(this.userClass=='enumeration_admin'||this.userClass=='registration_admin')
        {
            this.isAdminUser = true;
            this.consultant_id = this.$store.getters.getUser.consultantId;
            console.log(this.consultant_id);
        }

        if(this.id!=''&&this.type=='Edit')
        {
            this.isLoading = true;

            try
            {
                const response = await AuthService.getSingleUser(this.id);
        
                this.name = response.data.name;
                this.email = response.data.email;
                this.phone = response.data.phoneNo;
                this.role_id = response.data.primaryRole.id;
                this.consultant_id = (response.data.consultantId==null)? '' : response.data.consultantId;
                this.ward_id = (response.data.wardId==null)? '' : response.data.wardId;
                this.local_government_id = (response.data.localGovernmentId==null)? '' : response.data.localGovernmentId;
                this.state_id = (response.data.stateId==null)? '' : response.data.stateId;

                // 1. Debug 
                // console.log(response);
                // console.log(this.message);
                // console.log(this.isLoading);

                this.isLoading = false;
            }
            catch(error)
            {
                console.log(error);
                this.isLoading = false;
                this.message = error.message
            }
        }
     },
    
    getByValue(arr, value) 
    {
        for (var i=0, iLen=arr.length; i<iLen; i++) {

            if (arr[i].id == value) return arr[i];
        }
    },

     async States()
     {
         this.isStateLoading = true;

         try
         {
            const response = await AuthService.getStates('?perPage=37');
            console.log(response);
            this.states = response.data;

            if(this.type=='Edit')
            {
                const stateId = this.state_id;

                if(stateId!==null)
                {
                    const localGovId = this.local_government_id;
                    const wardId = this.ward_id;
                    const responseState = this.getByValue(this.states,stateId);
                    console.log('responseState : ');
                    console.log(this.states);
                    
                    if(responseState.localGovernments.length>0)
                    { 
                        const localGovs  = responseState.localGovernments;
                        this.lg_ck = localGovs;

                        if(wardId!==null&&localGovId!==null)
                        {
                            const singleLocalGov = this.getByValue(localGovs,localGovId);

                            if(singleLocalGov.wards.length>0)
                            {
                                this.wd_ck   = singleLocalGov.wards;
                            }
                        }
                    }
                }
            }

            this.isStateLoading = false;
         }
         catch(error)
         {
             this.isStateLoading = false;
             console.log(error);
         }
     },

     async Roles()
     {
         this.isRoleLoading = true;

         try
         {
            const response = await AuthService.getRoles();
            this.roles = response.data;
            
            console.log('# Debug - Show user class under : Roles()');
            console.log(this.userClass);
            console.log(this.roles);

            if(this.userClass=='enumeration_admin'||this.userClass=='registration_admin')
            {
                var role_access = ['enumerator']; 
                var temp_roles = []; 
                var temp_count = 0;

                if(this.userClass=='enumeration_admin')
                {
                    role_access.push('enumeration_admin','supervisor');
                }   

                if(this.userClass=='registration_admin')
                {
                    role_access.push('registration_admin');
                }

                for(var i = 0; i<this.roles.length; i++)
                {
                    if(role_access.includes(this.roles[i].name))
                    {
                        temp_roles[temp_count] = this.roles[i];
                        temp_count++;
                    }
                }
                
                this.roles = temp_roles;
            }

            this.isRoleLoading = false;
         }
         catch(error)
         {
             console.log(error); this.isRoleLoading = false;
         }
     },

    async Consultants()
    {
        if(this.userClass=='superadmin')
        {
            try
            {
                const response = await AuthService.getConsultants();
                console.log(response);
                this.consultants = response.data
            }
            catch(error)
            {
                console.log(error);
            }
        }
    },

    async LocalGovernments()
    {
         try
         {
            const response = await AuthService.getLocalGovernments();
            this.local_governments = response.data
         }
         catch(error)
         {
             console.log(error);
         }

    },

    async Wards()
    {
        try
        {
            const response = await AuthService.getWards();
            this.wards = response.data
        }
        catch(error)
        {
            console.log(error);
        } 
     },

     async addUserAction()
     { 
         this.isSubmitLoading = true;

         try
         {
            const data = this.usersData('Add');
            console.log(data);
            const response = await AuthService.addUser(data);
            const alertToken = response.data.id;
            this.$store.dispatch('setAlertToken', alertToken);
            this.isSubmitLoading = false;
            this.$router.push("/user/back/"+alertToken);

         }
         catch(error)
         {
             this.isSubmitLoading = false;
             this.isSubmitted = true;
             this.alertColor = "danger";
             console.log(error);

             if(error.message)
             {
                 this.alertMessage = error.message;
             }
             else
             {
                 this.alertMessage = "An error occured, please try again";
             }
         }
     },

     async updateUserAction()
     {
        this.isSubmitLoading = true;

        try
        {
            const data = this.usersData('Edit');
            console.log(data);
            const response = await AuthService.updateUser(this.id,data);

            if(response.data.id==this.id)
            {
                this.isSubmitLoading = false;
                this.isSubmitted = true;
                this.alertMessage = 'User updated successfully';
                this.password = '';
            }
        }
        catch(error){

             this.isSubmitLoading = false;
             this.isSubmitted = true;
             this.alertColor = "danger";
             console.log(error);

             if(error.message)
             {
                 this.alertMessage = error.message;
             }
             else
             {
                 this.alertMessage = "An error occured, please try again";
             }
        }
     },

     submitForm()
     {
         if(this.type=='Add')
         {
             this.addUserAction();
         }
         else if(this.type=='Edit')
         {
             this.updateUserAction();
         }
     },

     usersData(type)
     {
        var data = {
            name : this.name,
            email : this.email,
            primary_role : this.role_id,
            phone_no : this.phone 
            // consultant_id : this.consultant_id,
            // state_id : this.state_id,
        };

        if(type=='Add')
        {
            data['password'] = (this.password=='')? '123456789' : this.password;
        }

        if(type=='Edit')
        {
            if(this.password!=='')
            {
                data['password'] = this.password;
            }
        }

        if(this.local_government_id!=='')
        {
            data['local_government_id'] = this.local_government_id; 
        }

        if(this.consultant_id!=='')
        {
            data['consultant_id'] = this.consultant_id; 
        }


        if(this.state_id!=='')
        {
            data['state_id'] = this.state_id; 
        }

        if(this.ward_id!=='')
        {
            data['ward_id'] = this.local_government_id; 
        }

        return data;
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
      }
    },

    onChangeState(event)
    {
        const value = event.target.value;
        const id = event.target.options.selectedIndex-1;

        if(id>=0&&value!=='')
        {
            const data = this.states[id];

            // console.log('# Debug - State value & ID :')
            // console.log('State Value : '+value);
            // console.log('State ID : '+id);
            // console.log(data);

            if(data.id==value)
            {
                this.local_government_id = '';
                this.lg_ck = data.localGovernments;
                this.ward_id = '';
                this.wd.wd_ck = [];
            }
        }
        else
        {
            this.wd_ck = [];
            this.lg_ck = [];
        }
    },

    onChangeLocalGov(event)
    {
        const value = event.target.value;
        const id = event.target.options.selectedIndex-1;

        if(id>=0&&value!=='')
        {
            const data = this.lg_ck[id];

            // console.log('# Debug - Ward value & ID :');
            // console.log('Ward Value : '+value);
            // console.log('Ward ID : '+id);
            // console.log(data);

            if(data.id==value)
            {
                this.ward_id = '';
                this.wd_ck = data.wards
            }
        }
        else
        {
            this.wd_ck = [];
        }
    }

  }
};
</script>
