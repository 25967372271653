<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6>Area Count</h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <router-link :to="{ name: 'Dashboard' }">
                                    Dashboard <i class="fas fa-home text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">CLose</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-data-holder" v-if="isLoading==false && message==''">
                        <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th
                                    width="12"
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    #
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Area
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Update
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    %
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    New
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    %
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Total
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data,index) in TableData"  :key="data.id">
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{index + 1}}
                                            </h6>
                                        </div> 
                                    </td>
                                    <td>                                   
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{data.item}}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">{{data.businesses_updated.toLocaleString("en-US")}}</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            {{getStatePercentage(data.businesses_updated, data.item_count)}} %
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">{{data.businesses_added.toLocaleString("en-US")}}</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            {{getStatePercentage(data.businesses_added, data.item_count)}} %
                                        </div>
                                    </td> 
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">{{data.item_count.toLocaleString("en-US")}}</h6>
                                        </div>
                                    </td>                                 
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="d-flex p-3">
                                            <h6 class="mb-0 text-sm">
                                                TOTAL COUNT
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{TotalUpdated().toLocaleString("en-US")}}
                                            </h6>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="spiner-holder" v-else-if="isLoading==true && message==''">
                        <vsud-spinner></vsud-spinner>
                    </div>
                    <div class="nodata-holder" v-else>
                        <vsud-nodata :message="message"></vsud-nodata>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';

export default {
  name: "state-enumuration-activity",
  data()
  {
    return {
      TableData : [],
      isLoading : false,
      message : '',
      Enumeration : null,
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata
  },

  created()
  {
    this.getDashboardAnalytics();
  },

  methods :
  {
    async getDashboardAnalytics()
    {
       this.isLoading = true;
       
       try{
          const response = await AuthService.getDashboard('/areas');
          const dash = response.data.enumerationActivityByAreas;

           if(dash!==null)
           {
              this.TableData = dash;
              let sum = 0;
           }
       }
       catch(error)
       {
        if(error)
        { 
          this.message  = error.message;
          console.log(error);
        }
       }
       finally
       {
        this.isLoading = false;
       }
    },
    TotalUpdated()
    {
        var sum = 0;
        const data = this.TableData;

        for(var i=0 ; i<data.length; i++)
        {
            sum += data[i].item_count;
        }

        return sum;
    },
    getStatePercentage(num,den)
    {
      return ((num/den) * 100).toFixed(2);
    }
  }
};
</script>
